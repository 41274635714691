import React from 'react'
import "./Hero.css"
import { Container, Col, Row } from 'react-bootstrap'

const Hero = () => {
    return (
        <div className="hero-erpsolutions-container">
            <Container>
                <Row className="hero-erpsolutions">
                    <Col lg={6} className='hero-erpsolutions-content'>
                        
                            <h1 data-aos="fade-right" data-aos-duration="1500" className="hero-title">Enginia <span>ERP</span> Solutions</h1>
                            <p data-aos="fade-right" data-aos-duration="1500" className="hero-para">Open source ERP comes with many modules on a wide scale of business and for separate departments and proposes. Simple user interface with Many view methods.</p>
                      
                        <div className='hero-links'>
                            {/* <div>
                                <a herf="#" className="hero-link">Book Demo</a>
                            </div>
                            <div>
                                <a herf="#" className="hero-link">DOWNLOAD Document</a>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
           
                <img src={require("../../../../asset/images/ErpSolutions/hero.png")} className="hero-image" data-aos="fade-left" data-aos-duration="1500" />
            
        </div>
       
    )
}

export default Hero
