import React from 'react'
import "./Integrates.css"
import { Container, Row, Col } from "react-bootstrap"
import { Integrate} from "../../index"
import microsoft from "../../../../asset/images/ErpSolutions/Integrates/microsoft.svg"
import drive from "../../../../asset/images/ErpSolutions/Integrates/drive.svg"
import outlook from "../../../../asset/images/ErpSolutions/Integrates/outlook.svg"
import arrowRight from "../../../../asset/images/ErpSolutions/Integrates/arrowright.svg"
import arrowLeft from "../../../../asset/images/ErpSolutions/Integrates/arrowleft.svg"
import Color5 from "../../../../asset/images/vectors/color5.webp";

const Integrates = () => {
    return (
    
        <Container className='erp-integrates'>
           {/* <img id="color5" src={Color5} alt="color5" /> */}
           <Integrate
                image={require("../../../../asset/images/ErpSolutions/Integrates/integrate1.png")}
                title={"Integrate with"}
                titleImg={microsoft}
                para={"Integrating your website with Microsoft 365 brings a wealth of productivity and collaboration features directly to your users' fingertips. With this integration, your website becomes seamlessly connected to the powerful suite of Microsoft tools, enhancing user experience and streamlining workflow."}
                arrow={arrowRight}
           />
           <Integrate
                image={require("../../../../asset/images/ErpSolutions/Integrates/integrate2.png")}
                title={"Integrate with"}
                titleImg={outlook}
                para={"Connect your system with Outlook Calendar for effortless scheduling. Users can seamlessly sync appointments and tasks, keeping their schedules organized without leaving your platform."}
                dir={"row-reverse"}
                flexEnd={true}
                arrow={arrowLeft}
           />
           <Integrate
                image={require("../../../../asset/images/ErpSolutions/Integrates/integrate3.png")}
                title={"Integrate with"}
                titleImg={drive}
                para={"Integrate your system with OneDrive to streamline file management. With this feature, users can upload, delete, and update files directly from your ERP system, enhancing collaboration and efficiency."}
           />
        </Container>
    )
}

export default Integrates
