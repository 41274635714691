import React, {useEffect} from 'react'
import "./DevicesGallery.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color3 from "../../../../asset/images/vectors/color3.webp";
import Color4 from "../../../../asset/images/vectors/color4.webp";
import { WOW } from 'wowjs';
import 'animate.css';



const DevicesGallery = () => {
    useEffect(() => {
        new WOW().init();
      }, []);

    return (
        <div className='devices-gallery'>
            <div className="color-div2">
                <img id="color3" src={Color3} alt="color3" />
                <img id="color4" src={Color4} alt="color4" />
            </div>
            <div className='devices-gallery-container container'>
                <Row>
                    <Col xs={4} md={4}>
                        <div className='device-gallery-col-container wow animate__animated animate__fadeInUp'  data-wow-duration="2s">
                            <div className='device-gallery-col-content'>
                                <img src={require('../../../../asset/images/MobileService/device-gallery-1.webp')} alt='device-gallery' />
                            </div>
                        </div>
                    </Col>

                    <Col xs={4} md={4}>
                        <div className='device-gallery-col-container'  >
                            <div className='device-gallery-col-content'>
                                <img className='wow animate__animated animate__fadeInDown' data-wow-duration="2s" src={require('../../../../asset/images/MobileService/device-gallery-2.webp')} alt='device-gallery' />
                            </div>
                        </div>
                    </Col>

                    <Col xs={4} md={4}>
                        <div className='device-gallery-col-container wow animate__animated animate__fadeInUp'  data-wow-duration="2s" >
                            <div className='device-gallery-col-content'>
                                <img src={require('../../../../asset/images/MobileService/device-gallery-3.webp')} alt='device-gallery' />
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>

        </div>
    )
}

export default DevicesGallery
