import React from 'react'
import "./Hero.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color1 from "../../../../asset/images/vectors/color1.webp";
import Color2 from "../../../../asset/images/vectors/color2.webp";



const Hero = () => {

    return (
        <div>
            <div className="color-div1">
                <img id="color1" src={Color1} alt="color1" />
                <img id="color2" src={Color2} alt="color2" />
            </div>
            <div className='mobile-hero'>
                <div className='mobile-hero-container container'>

                    <div className='mobile-hero-content'>

                        <Row>
                            <Col xs={12} md={6}>
                                <div className='mobile-hero-text-container' data-aos="fade-right" data-aos-duration="1800">
                                    <div className='mobile-hero-text-content'>
                                        <div className='mobile-hero-text-title'>
                                            Mobile Apps
                                        </div>
                                        <div className='mobile-hero-text-des'>
                                            At Enginia Solutions LLC, we develop custom mobile applications that enhance business efficiency and decision-making. Experience user-friendly apps designed to meet your specific needs.
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} md={6} >
                                <div className='mobile-hero-image-container' data-aos="fade-left" data-aos-duration="1800">
                                    <div className='mobile-hero-image-content'>
                                        <img src={require('../../../../asset/images/MobileService/hero.webp')} alt='mobile-hero' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Hero
