import React from 'react'
import "./Feature.css"

const Feature = ({ title, para, image }) => {
    return (
        <div className="feature-container" data-aos="zoom-in" data-aos-duration="1500">
            <h1 className="feature-title">{title}</h1>
            <p className="feature-para">{para}</p>
            <img className="feature-image" src={image} />
        </div>
    )
}

export default Feature
