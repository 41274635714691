import React from 'react';
import "./TechnologyStack.css";
import { Col, Container, Row } from 'react-bootstrap';

const TechnologyStack = () => {

    return (
        <div className='stack-div'>
            <div className='container'>
                <div className='stack-title' data-aos="fade-down" data-aos-duration="1800">Technology Stack</div>
                <Row className='stack-row'>
                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>Programming Language</div>
                            <div className='lang'>
                                <span>Python, Dart</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>Frameworks</div>
                            <div className='lang'>
                                <span>Flutter, React native</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>Database Management</div>
                            <div className='lang'>
                                <span>Firebase, SQLite</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>APIs and Integration Tools</div>
                            <div className='lang'>
                                <span>RESTful, Payment Gateway</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>Backend</div>
                            <div className='lang'>
                                <span>Frappe</span>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>

        </div>
    )
}

export default TechnologyStack;
