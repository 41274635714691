import React, { useEffect } from 'react'
import "./WebsiteServices.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color3 from "../../../../asset/images/vectors/color3.webp";
import Color4 from "../../../../asset/images/vectors/color4.webp";
import { WOW } from 'wowjs';
import 'animate.css';



const WebsiteServices = () => {
    useEffect(() => {
        new WOW().init();
    }, []);

    return (
        <div className='website-services'>
            <div className="color-div2">
                <img id="color3" src={Color3} alt="color3" />
                <img id="color4" src={Color4} alt="color4" />
            </div>
            <div className='website-services-container container'>
                <Row className='website-services-rows-container'>
                    <Row className='website-services-row odd-row'>
                        <Col xs={12} md={6}>

                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                    <img className=' wow animate__animated animate__slideInLeft' data-wow-duration="1.5s" src={require('../../../../asset/images/WebsiteService/website-service-1.webp')} alt='device-gallery' />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                    <div className='website-service-text-content  wow animate__animated animate__slideInRight' data-wow-duration="1.5s">
                                        <div className='website-service-text-title'>
                                            <span>Cross-Platform Compatibility</span>
                                        </div>
                                        <div className='website-service-text-des'>
                                            <span>Develop mobile applications that run smoothly across multiple platforms, including iOS and Android. This service ensures your app can reach a wider audience without sacrificing functionality or user experience on any device.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='arrow-container' data-aos="fade-down" data-aos-duration="1500">
                    <img src={require('../../../../asset/images/MobileService/arrow.png')} alt='arrow' />
                    </div>

                    <Row className='website-services-row'>
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                    <div className='website-service-text-content  wow animate__animated animate__slideInLeft' data-wow-duration="1.5s">
                                        <div className='website-service-text-title'>
                                            <span>AI-Enhanced Web Applications</span>
                                        </div>
                                        <div className='website-service-text-des'>
                                            <span>Incorporate advanced artificial intelligence into your web applications to automate processes, personalize user experiences, and gain valuable insights from data. Ideal for businesses looking to leverage AI for smarter decision-making and enhanced user engagement on their web platforms.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                <img className=' wow animate__animated animate__slideInLeft' data-wow-duration="1.5s" src={require('../../../../asset/images/WebsiteService/website-service-2.webp')} alt='device-gallery' />
                                </div>
                            </div>
                        </Col>
                    </Row>




                    <div className='arrow-container' data-aos="fade-down" data-aos-duration="1500">
                        <img src={require('../../../../asset/images/MobileService/arrow-reverse.png')} alt='arrow' />
                    </div>

                    <Row className='website-services-row odd-row'  >
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                <img className=' wow animate__animated animate__slideInLeft' data-wow-duration="1.5s" src={require('../../../../asset/images/WebsiteService/website-service-3.webp')} alt='device-gallery' />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                    <div className='website-service-text-content wow animate__animated animate__slideInRight' data-wow-duration="1.5s" >
                                        <div className='website-service-text-title'>
                                            <span>Real-Time Data Analytics</span>
                                        </div>
                                        <div className='website-service-text-des'>
                                            <span>Equip your web application with real-time data analytics capabilities to monitor user behavior and website performance instantly. This service is crucial for businesses that need to make quick decisions based on up-to-the-minute data, ensuring optimal performance and user satisfaction.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className='arrow-container' data-aos="fade-down" data-aos-duration="1500">
                        <img src={require('../../../../asset/images/MobileService/arrow.png')} alt='arrow' />
                    </div>

                    <Row className='website-services-row' >
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                    <div className='website-service-text-content  wow animate__animated animate__slideInLeft' data-aos-duration="1500">
                                        <div className='website-service-text-title' >
                                            <span>Seamless Payment Gateway Integration</span>
                                        </div>
                                        <div className='website-service-text-des'>
                                            <span>Enhance your web application with secure and seamless payment gateway integration, allowing for easy and efficient transactions. Perfect for e-commerce and service-based platforms aiming to provide a smooth and hassle-free checkout experience for users.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='website-service-container'>
                                <div className='website-service-col-content'>
                                <img className=' wow animate__animated animate__slideInLeft' data-wow-duration="1.5s" src={require('../../../../asset/images/WebsiteService/website-service-4.webp')} alt='device-gallery' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>

            </div>

        </div>
    )
}

export default WebsiteServices
