import React from 'react';
import './ProjectVideo.css';
import { Col, Container, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';
import Arrow2 from '../../../../asset/images/vectors/works-arrow.png';

const ProjectVideo = ({ data, url, dictValues }) => {
  if (!dictValues || !dictValues.section3_video) {
    return null;
  }

  let videoId = ''; // Replace with your YouTube video ID

  if (dictValues.section3_video.startsWith("https://www.youtube.com/watch?v=")) {
    videoId = dictValues.section3_video.split("v=")[1];
  } else {
    videoId = dictValues.section3_video;
  }

  return (
    <div className='project-video-div' data-aos="fade-down" data-aos-duration="1800">
      <img src={Arrow2} className="arrow2" alt="arrow"></img>
      <div className='video-title'>{dictValues.section3_title}</div>
      <div className='video-text' dangerouslySetInnerHTML={{ __html: dictValues.section3_details }}></div>
      <div className='video-div'>
        <YouTube videoId={videoId} />
        <div className='video-back-div'></div>
      </div>
    </div>
  );
}

export default ProjectVideo;
