import React from 'react'
import "./AboutService.css"

const AboutService = () => {
    return (
        <div>
            <div className='ai-about-service'>
                <div className='ai-about-service-container container'>
                    <div className='ai-about-service-content'>

                        <div className='ai-about-service-title'>
                            About Service
                        </div>

                        <div className='ai-about-service-des'>
                            Harness the transformative power of artificial intelligence with Enginia Solutions LLC. Our AI - Innovation service leverages cutting-edge technologies to create smart, adaptive solutions that propel businesses forward. Our AI development service can help you create custom AI solutions for your business needs using the <b>BEST AI</b> technologies. We offer quality, reliability, support, and affordability
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutService
