import React, {useRef, useEffect} from 'react'
import "./Loading.css"
import lottie from 'lottie-web';

const Loading = ({ animationData, animationSpeed }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    anim.setSpeed(animationSpeed);

    return () => {
      anim.destroy(); // Cleanup animation on unmount
    };
  }, [animationData, animationSpeed]);

  return <div ref={containerRef} />;
};

export default Loading