import React from 'react'
import "./Discretion.css"
import { Container } from 'react-bootstrap'
import { Module } from "../../index"

const Discretion = () => {
    return (
        <Container className='discretion'>
            <h1 className='discretion-title'>Description</h1>
            <p className='discretion-para'>
                Welcome to Enginia ERP Solutions, your one-stop destination for comprehensive and customizable Open Source ERP solutions. Our flagship offering, ERPNext, is a robust platform designed to streamline operations across various departments and businesses of all sizes.
            </p>
            <p className='discretion-para'>
                With <span>ERPNext</span>, you gain access to a versatile suite of modules, each tailored to address specific needs and challenges within your organization. From seamless Accounting and Purchase management to efficient Manufacturing processes and HR functionalities, our ERP solution covers it all.            
            </p>
            <h1 className='discretion-second-title'>ERPNext Modules:</h1>
            <div className='erp-modules'>
                <Module title={"Accounting"} />
                <Module title={"CRM"} />
                <Module title={"Sales"} />
                <Module title={"HR"} />
                <Module title={"Asset Management"} />
                <Module title={"Manufacturing"} />
                <Module title={"Payroll"} />
                <Module title={"Quality Management"} />
                <Module title={"Stock Management"} />
            </div>
        </Container>
    )
}

export default Discretion
