import React from 'react'
import "./AiServices.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import nlpIcon from "../../../../asset/images/AiService/nlp_icon.svg"
import aiSoftwareIcon from "../../../../asset/images/AiService/ai_software_icon.svg"
import aiSocialMedia from "../../../../asset/images/AiService/ai_social_media_icon.svg"
import dataAnalyseIcon from "../../../../asset/images/AiService/data_analyse_icon.svg"
import Color3 from "../../../../asset/images/vectors/color3.webp";
import Color4 from "../../../../asset/images/vectors/color4.webp";



const AboutService = () => {
    return (
        <div>
            <div className='ai-services'>
                <div className="color-div2">
                    <img id="color3" src={Color3} alt="color3" />
                    <img id="color4" src={Color4} alt="color4" />
                </div>
                <div className='ai-services-container container'>
                    <div className='ai-services-content'>

                        <div className='ai-services-title' data-aos="fade" data-aos-duration="3000">
                            <div className='ai-services-title-content'>
                                <span className='ai-services-title-1'>What We Offer?</span>
                                <span className='ai-services-title-2'>Here’s a list of AI software services Enginia can assist you with.</span>
                            </div>
                        </div>

                        <div className='ai-services-list' data-aos="fade" data-aos-duration="3000">
                            <Row>

                                <Col xs={12} md={6}>
                                    <div className='ai-service-container'>
                                        <div className='ai-service-content'>
                                            <div className='ai-service-title'>
                                                <div className='ai-service-title-icon'>
                                                    <img src={nlpIcon} alt='ai-hero' />
                                                </div>
                                                <div className='ai-service-title-des'>
                                                    Natural Language Processing
                                                </div>
                                            </div>
                                            <div className='ai-service-des'>
                                                Our Natural Language Processing (NLP) solutions help understand and analyze text and speech. Use this AI service to create voice assistants, understand emotions and meanings, and improve your communication strategy.
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} md={6}>
                                    <div className='ai-service-container'>
                                        <div className='ai-service-content'>
                                            <div className='ai-service-title'>
                                                <div className='ai-service-title-icon'>
                                                    <img src={aiSoftwareIcon} alt='ai-hero' />
                                                </div>
                                                <div className='ai-service-title-des'>
                                                    Custom AI Software
                                                </div>
                                            </div>
                                            <div className='ai-service-des'>
                                                Whether you need to analyze medical data, create recommendation lists, predict prices for sales, or achieve other business goals, our experts will assess your needs and develop the ideal custom solution for you.                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} md={6}>
                                    <div className='ai-service-container'>
                                        <div className='ai-service-content'>
                                            <div className='ai-service-title'>
                                                <div className='ai-service-title-icon'>
                                                    <img src={aiSocialMedia} alt='ai-hero' />
                                                </div>
                                                <div className='ai-service-title-des'>
                                                    Social Media
                                                </div>
                                            </div>
                                            <div className='ai-service-des'>
                                                We provide a solution for managing your Instagram and Facebook content. Create, schedule, and analyze posts with our tools to save time, money, and grow your online presence.                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={12} md={6}>
                                    <div className='ai-service-container'>
                                        <div className='ai-service-content'>
                                            <div className='ai-service-title'>
                                                <div className='ai-service-title-icon'>
                                                    <img src={dataAnalyseIcon} alt='ai-hero' />
                                                </div>
                                                <div className='ai-service-title-des'>
                                                    Data analysis
                                                </div>
                                            </div>
                                            <div className='ai-service-des'>
                                                Speed up data structuring and analysis with AI and machine learning. Our AI app development helps you gain valuable insights and easily generate organized reports.                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>

                        <div className='ai-services-arrow'>
                            <div className='ai-services-arrow container' data-aos="fade-down" data-aos-duration="1800">
                                <img src={require('../../../../asset/images/AiService/ai_arrow.png')} alt='ai-arrow' />
                            </div>
                        </div>

                        <div className='ai-services-chatbot'>
                            <div className='ai-services-chatbot-container container'>
                                <div className='ai-services-chatbot-button' data-aos="fade" data-aos-duration="1800">
                                    <img src={require('../../../../asset/images/AiService/enginia-chatbot-icon.png')} alt='ai-arrow' />
                                    <div className='ai-services-chatbot-button-text'>
                                        <span>Enginia ChatBot</span>
                                    </div>
                                </div>

                                <div className='ai-services-chatbot-images'>

                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div data-aos="fade-left" data-aos-duration="1800" className='ai-services-chatbot-image-container'>
                                                <div className='ai-services-chatbot-image-content'>
                                                    <img src={require('../../../../asset/images/AiService/hero-image.webp')} alt='ai-chatbot' />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <div data-aos="fade-right" data-aos-duration="1800" className='ai-services-chatbot-image-container'>
                                                <div className='ai-services-chatbot-image-content'>
                                                    <img src={require('../../../../asset/images/AiService/chatbot_2.webp')} alt='ai-chatbot' />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>

                                <div className='ai-services-chatbot-des'>
                                    <span>
                                        Our custom-built chatbot performs multiple tasks, including summarizing content, understanding data, and processing attached files. It's designed to handle complex requests efficiently, providing accurate and timely responses.
                                    </span>
                                </div>

                                <div className='ai-services-chatbot-tasks-container' data-aos="fade" data-aos-duration="3000">
                                        <div className='ai-services-chatbot-tasks-content'>
                                                <img src={require('../../../../asset/images/AiService/chatbot-tasks-w.png')} alt='ai-chatbot' />
                                        </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutService
