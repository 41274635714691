import React from 'react'
import "./IndustryServices.css"
import industryChart from "../../../../asset/images/AiService/industry_chart.svg"
import industryChartSmall from "../../../../asset/images/AiService/industry_chart_small.svg"
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';


const IndustryServices = () => {
    
    return (
        <div>
            <div className='ai-industry-services'>
                <div className='ai-industry-services-container container'>
                    <div className='ai-industry-services-content'>

                        <div className='ai-industry-services-title' data-aos="fade" data-aos-duration="3000">
                            <span>
                                AI development services for any industry
                            </span>
                        </div>

                        <div className='ai-industry-services-chart-container' data-aos="fade" data-aos-duration="3000">
                            <div className='ai-industry-services-chart-content'>
                                <img
                                    src={industryChart}
                                    alt='industryChart'
                                    className='industry-chart-large'
                                />
                                <img
                                    src={industryChartSmall}
                                    alt='industryChartSmall'
                                    className='industry-chart-small'
                                />
                            </div>
                        </div>

                        <div className='ai-services-arrow'>
                            <div className='ai-services-arrow container' data-aos="fade-down" data-aos-duration="1800">
                                <img src={require('../../../../asset/images/AiService/ai_arrow.png')} alt='ai-arrow' />
                            </div>
                        </div>

                        <div className='hero-links'>
                            <div>
                                <HashLink  className="hero-link" smooth to={"/home#contact"}>
                                    Ready to discuss your project
                                </HashLink>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryServices
