import React from 'react'
import "./WhatWeDo.css"
import { Service } from "../../index"
import { Container, Row, Col } from 'react-bootstrap'
import serviceOne from "../../../../asset/images/ErpSolutions/Services/serviceOne.svg"
import serviceTwo from "../../../../asset/images/ErpSolutions/Services/serviceTwo.svg"
import serviceThree from "../../../../asset/images/ErpSolutions/Services/serviceThree.svg"
import serviceFour from "../../../../asset/images/ErpSolutions/Services/serviceFour.svg"
import serviceFive from "../../../../asset/images/ErpSolutions/Services/serviceFive.svg"
import serviceSix from "../../../../asset/images/ErpSolutions/Services/serviceSix.svg"

const WhatWeDo = () => {
    return (
        <Container className="what-we-do">
            <div data-aos="fade-down" data-aos-duration="1800" >
                <h1 className='what-we-do-title' >What we Do ?</h1>
                <p className='what-we-do-para'>Our comprehensive technical support and training package encompasses</p>
            </div>
           
            <Row>
                <Col md={4}>
                    <Service
                        image={serviceOne}
                        title={"ERP Installation"}
                        para={"Get your ERP Next up and running smoothly with our expert installation service."}
                    />
                </Col>
                <Col md={4}>
                    <Service
                        image={serviceTwo}
                        title={"ERP Customization"}
                        para={"Tailor your ERP to fit your unique business needs with our expert customization services."}
                    />
                </Col>
                <Col md={4}>
                    <Service
                        image={serviceThree}
                        title={"Cloud technical support"}
                        para={"Ensure seamless operations and optimal performance with our cloud technical support services."}
                    />
                </Col>
                <Col md={4}>
                    <Service
                        image={serviceFour}
                        title={"Training sessions "}
                        para={"Empower your workforce with targeted training sessions tailored to your team's needs."}
                    />
                </Col>
                <Col md={4}>
                    <Service
                        image={serviceFive}
                        title={"Quality assurance"}
                        para={"Ensure system reliability and performance with thorough testing protocols."}
                    />
                </Col>
                <Col md={4}>
                    <Service
                        image={serviceSix}
                        title={"third party integration"}
                        para={"Expand the capabilities of your system with seamless third-party integrations."}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default WhatWeDo
