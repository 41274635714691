import React from 'react'
import "./ProjectDetails.css"
import { Col, Container, Row } from 'react-bootstrap'
import ProImg from '../../../../asset/images/ProjectDetails/t1 1.png';
import ProImg2 from '../../../../asset/images/ProjectDetails/t2 1.png';
import Arrow1 from '../../../../asset/images/vectors/arrow1.png';



const ProjectDetails = ({ data, url, dictValues }) => {
  const isSection1Valid = dictValues.section1_image && dictValues.section1_title && dictValues.section1_details;
  const isSection2Valid = dictValues.section2_image && dictValues.section2_title && dictValues.section2_details;
  const arrow1HiddenClass = !isSection2Valid ? "arrow1-hidden" : "";

  return (
    <div className="pro-details-div">
      {isSection1Valid && (
        <Row className="pro-details-row">
          <div className="col-12 col-md-6 project-details-image">
            <img src={url + dictValues.section1_image} alt="ProImg" data-aos="fade-left" data-aos-duration="1800" className="pro-img" />
          </div>
          <div className="col-12 col-md-6">
            <div data-aos="fade-right" data-aos-duration="1800" className="pro-title">{dictValues.section1_title}</div>
            <div data-aos="fade-right" data-aos-duration="1800" className="pro-description" dangerouslySetInnerHTML={{ __html: dictValues.section1_details }}></div>
          </div>
          <div data-aos="fade-down" data-aos-duration="1500" >
          <img src={Arrow1}  className={`arrow1 ${arrow1HiddenClass}`} alt="Arrow" />

          </div>
        </Row>
      )}

      {isSection2Valid && (
        <Row className="pro-details-row">
          <div className="col-12 col-md-6">
            <div data-aos="fade-left" data-aos-duration="1800"  className="pro-title">{dictValues.section2_title}</div>
            <div data-aos="fade-left" data-aos-duration="1800"  className="pro-description" dangerouslySetInnerHTML={{ __html: dictValues.section2_details }}></div>
          </div>
          <div className="col-12 col-md-6 project-details-image">
            <img  data-aos="fade-right" data-aos-duration="1800" src={url + dictValues.section2_image} alt="ProImg" className="pro-img second-details-image" />
          </div>
        </Row>
      )}
    </div>
  );
};

export default ProjectDetails;