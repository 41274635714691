import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Header = ({ firstTitle, title, para }) => {
    return (
        <div className='header'>
            <Container className='header-container'>
                <h1 className='header-title'><span>{firstTitle}</span> {title}</h1>
                <h1 className='header-desc'>{para}</h1>
            </Container>
        </div>
    )
}

export default Header
