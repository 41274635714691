import React from 'react'
import "./AboutService.css"

const AboutService = () => {
    return (
        <div>
            <div className='mobile-about-service'>
                <div className='mobile-about-service-container container'>
                    <div className='mobile-about-service-content'>
                        <div className='mobile-about-service-title'>
                            About Service
                        </div>
                        <div className='mobile-about-service-des'>
                            Enginia Solutions LLC specializes in creating state-of-the-art web applications that are custom-designed to meet the unique needs of your business. Leveraging advanced AI technology, our web solutions not only streamline operations but also provide key insights to drive your decision-making process. Partner with us to transform your ideas into innovative web applications that deliver exceptional user experiences.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutService
