import React from 'react'
import "./Hero.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color1 from "../../../../asset/images/vectors/color1.webp";
import Color2 from "../../../../asset/images/vectors/color2.webp";



const Hero = () => {

    return (
        <div>
            <div className="color-div1">
                <img id="color1" src={Color1} alt="color1" />
                <img id="color2" src={Color2} alt="color2" />
            </div>
            <div className='website-about'>
                <div className='website-about-container container'>

                    <div className='website-about-content'>

                        <Row>
                            <Col xs={12} md={6}>
                                <div className='website-about-text-container' data-aos="fade-right" data-aos-duration="1800">
                                    <div className='website-about-text-content'>
                                        <div className='website-about-text-title'>
                                            Web Applications
                                        </div>
                                        <div className='website-about-text-des'>
                                            At Enginia Solutions LLC, we develop custom web applications that enhance business efficiency and decision-making. Experience intuitive and responsive web solutions designed to meet your specific needs.

                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} md={6} >
                                <div className='website-about-image-container' data-aos="fade-left" data-aos-duration="1800">
                                    <div className='website-about-image-content'>
                                        <img src={require('../../../../asset/images/WebsiteService/hero.webp')} alt='website-about' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Hero
